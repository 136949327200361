<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          v-show="isModuleActualEqualTwo"
          variant="success"
          class="mr-1"
          @click="openModalSearch()"
        >
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
          />Search Payment
        </b-button>
      </template>
    </header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'pending-clients-specialistdigital' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >New</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'completed-clients-specialistdigital' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Regular</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>

    <add-round-modal
      v-if="addRoundModal"
      :data-round="itemClient"
      :validate-modal="0"
      @close="addRoundModal = false"
    />

    <list-all-notes-modal
      v-if="isAllNotesActive"
      :id-account="notesClient.idAccount"
      :name-client="notesClient.nameClient"
      @close="closeModal"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
// modals
import ToggleModal from "@/mixins/modal";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";

export default {
  components: {
    AddRoundModal,
    ListAllNotesModal,
    ModalSearchPayment,
  },
  mixins: [ToggleModal],
  data() {
    return {
      openModalSearchPayment: false,
      moduleActual: null,
      permitStatus: null,
      notesClient: {
        nameClient: "Faustino Barajas",
        idAccount: "2744fdc7-56ce-11eb-9a2c-16ec3745526d",
        month: 1,
        year: "2022",
      },
      itemClient: {
        id: "55baaad7-a556-11ec-a9ed-169df0eae141",
        idAccount: "2744fdc7-56ce-11eb-9a2c-16ec3745526d",
        account: "BC2000887",
        clientName: "Faustino Barajas",
      },
      addRoundModal: false,
      isAllNotesActive: false,
    };
  },
  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
  },
  created() {
    this.moduleId();
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    openAddRoundModal() {
      this.addRoundModal = true;
    },
    openAllNotes() {
      this.isAllNotesActive = true;
    },
    closeModal() {
      this.isAllNotesActive = false;
      this.addRoundModal = false;
    },
  },
};
</script>

<style scoped>
</style>
